
import { useParams, useSearchParams } from "next/navigation";
import { Category } from "src/api/types";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import findCategoryObject from "src/utils/categories/findCategoryObject";
import { sendCategoryClick } from "../../helpers/analytics";
import { getCategoryHrefWithSort } from "../../helpers/getCategoryHrefWithSort";
import { CategoryTagProps } from "../CategoryTag/CategoryTag";

interface Params {
  categories: Category[];
  categoryLevel: number;
  selectedCategory: Category;
}

const useCategoriesTags = ({ categories, categoryLevel, selectedCategory }: Params): CategoryTagProps[] => {
  const lang = useLang();
  const searchParams = useSearchParams();
  const params = useParams();
  const localize = useLocalize();
  const currentCategoryId = params?.categoryId;
  const activeSort = searchParams?.get('sort');

  const isSecondLevel = categoryLevel === 2;

  const parentId = selectedCategory.parent_id as string;

  const firstTagCategoryId = isSecondLevel
    ? selectedCategory.id
    : parentId;
  const place = 'category_tags';

  const firstCategoryTag: CategoryTagProps = {
    title: localize('all'),
    href: getCategoryHrefWithSort({
      lang,
      activeSort,
      categoryId: firstTagCategoryId,
    }),
    isActive: isSecondLevel,
    onClick: () => sendCategoryClick({
      category_id: firstTagCategoryId,
      page: `category_lvl${categoryLevel}`,
      place,
      slider_index: 0,
    }),
  };

  const categoriesToShow: Category[] = (isSecondLevel
    ? selectedCategory.children
    : findCategoryObject(categories, parentId)?.children)
    || [];

  const categoriesTags: CategoryTagProps[] = categoriesToShow.map((c, i) => ({
    title: c.title,
    href: getCategoryHrefWithSort({
      lang,
      activeSort,
      categoryId: c.id,
    }),
    isActive: currentCategoryId === c.id,
    onClick: () => sendCategoryClick({
      category_id: c.id,
      page: `category_lvl${categoryLevel}`,
      place,
      slider_index: i + 1,
    }),
  }));

  return [firstCategoryTag, ...categoriesTags];
};

export default useCategoriesTags;
