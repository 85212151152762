import { Category } from "src/api/types";
import CategoriesTags from "../CategoriesTags/CategoriesTags";
import FirstLevelCategoriesSlider from "./FirstLevelCategoriesSlider";

export interface CategoryPageContentHeaderProps {
  categoryLevel: number;
  title: string;
  categories: Category[];
  selectedCategory: Category;
}

const CategoryPageContentHeader = (props: CategoryPageContentHeaderProps) => {
  const { categoryLevel, title, selectedCategory, categories } = props;

  if (categoryLevel === 1) {
    return (
      <FirstLevelCategoriesSlider title={title} categories={selectedCategory.children} />
    );
  }

  return (
    <CategoriesTags
      selectedCategory={selectedCategory}
      categories={categories}
      categoryLevel={categoryLevel}
      title={title}
    />
  );
};

export default CategoryPageContentHeader;
