import styled from '@emotion/styled';

export const CatalogFiltersMobileCategoriesContentStyled = styled.div`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .CatalogFiltersMobileCategories__item {
    display: flex;
    align-items: center;
  }
`;
