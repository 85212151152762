
let cachedSupportsTouchActionNone: boolean;

export const doesSupportTouchActionNone = () => {
  if (cachedSupportsTouchActionNone === undefined) {
    if (typeof CSS !== 'undefined' && typeof CSS.supports === 'function') {
      cachedSupportsTouchActionNone = CSS.supports('touch-action', 'none');
    } else {
      cachedSupportsTouchActionNone = true;
    }
  }
  return cachedSupportsTouchActionNone;
};

export const getNewPointPx = (
  event: TouchEvent | MouseEvent | React.MouseEvent,
  touchId: React.RefObject<number | null>,
) : number | false => {
  // The event is TouchEvent
  if (touchId.current !== null && (event as TouchEvent).changedTouches) {
    const touchEvent = event as TouchEvent;
    for (let i = 0; i < touchEvent.changedTouches.length; i += 1) {
      const touch = touchEvent.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return touch.clientX;
      }
    }

    return false;
  }

  // The event is MouseEvent
  return (event as MouseEvent).clientX;
};

export const valueToPercent = (value: number, min: number, max: number): number =>
  ((value - min) * 100) / (max - min);

export const percentToValue = (percent: number, min: number, max: number): number =>
  (max - min) * (percent / 100) + min;

export const roundValueToStep = (value: number, step: number, min: number): number =>
  Math.round((value - min) / step) * step + min;

export const getNewPointPercent = (newPointPx: number, rootEl: HTMLElement | null): number => {
  if (!rootEl) {
    return 0;
  }

  const { width, x } = rootEl.getBoundingClientRect();

  return valueToPercent(newPointPx, x, x + width);
};

export const digitsByAsc = (a: number, b: number): number => a - b;

export const getActiveTrackPosition = (
  thumbsPositions: number[],
  ): { left: `${ number }%`; right: `${ number }%` } => {
  if (thumbsPositions.length === 1) {
    return {
      left: '0%',
      right: `${ 100 - thumbsPositions[0] }%`,
    };
  }

  return {
    left: `${ thumbsPositions[0] }%`,
    right: `${ 100 - thumbsPositions[1] }%`,
  };
};
