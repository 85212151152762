import { CatalogProductBase } from "src/api/types";
import ProductsSlider from "src/components/ProductsSlider/ProductsSlider";
import CatalogProductTile from "../CatalogProductTile/CatalogProductTile";
import { Typography } from "src/ui-kit/Typography";
import { ProductPlace } from "src/utils/enhancedEcommerce";
import useFavoritesService from "src/services/favorites/useFavoritesService";

interface UberCatalogProductsSliderProps {
  title: string;
  products: CatalogProductBase[];
  place: ProductPlace;
  dataMarker?: string;
}

const UberCatalogProductsSlider = (props: UberCatalogProductsSliderProps) => {
  const { title, products, place, dataMarker } = props;
  const {
    getProductIsFavorite,
    toggleFavorite,
  } = useFavoritesService();

  return (
    <ProductsSlider
      dataMarker={`Slider Viewport ${dataMarker}`}
      isFetching={false}
      titleSlot={
        <Typography
          dataMarker={`${dataMarker} Title`}
          variant="h4"
          sbs={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {title}
        </Typography>
      }
    >
      {products.map(product => (
        <CatalogProductTile
          key={`${product.ean}-slider-item`}
          product={product}
          place={place}
          isFavorite={getProductIsFavorite(product.eans)}
          onFavoriteClick={() => toggleFavorite({ product, place })}
        />
      ))}
    </ProductsSlider>
  );
};



export default UberCatalogProductsSlider;
