import { useParams, usePathname, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { CatalogProductBase } from "src/api/types";
import { DEFAULT_PRODUCTS_PER_PAGE } from "src/data/constants";
import { useRouterWithLoading } from 'src/modules/global-loading';
import useLang from "src/services/language/useLang";
import getQueryWithMergedFilterParams from "src/utils/catalog/getQueryWithMergedFilterParams";
import makeSearchString from "src/utils/system/makeSearchString";
import { getQueryObjectFromSearchParams } from "src/utils/system/searchParamsHelpers";
import { CatalogProductTileProps } from "../components/CatalogProductTile/CatalogProductTile";
import { CatalogData, LoadMoreAndPaginationParams, LoadMoreAndPaginationProps, PaginationProps } from "./useLoadMoreAndPaginationTypes";
import useFavoritesService from "src/services/favorites/useFavoritesService";
import { ProductPlace } from "src/utils/enhancedEcommerce";

const useLoadMoreAndPagination = <T extends CatalogData>(
  params: LoadMoreAndPaginationParams<T>,
): LoadMoreAndPaginationProps => {
  const {
    products,
    categoryId,
    productsCount,
    sort,
    fetchDataHandler,
    isFuzzy,
  } = params;

  const [loadMoreIsFetching, setLoadMoreIsFetching] = useState(false);
  const [loadMoreProducts, setLoadMoreProducts] = useState<CatalogProductBase[]>([]);
  const language = useLang();
  const {
    getProductIsFavorite,
    toggleFavorite,
  } = useFavoritesService();
  const router = useRouterWithLoading();
  const searchParams = useSearchParams();
  const { firstLevelFilter } = useParams();
  const pathname = usePathname();
  const query = useMemo(() => getQueryObjectFromSearchParams(searchParams), [searchParams]);
  const queryPage = Number(searchParams?.get('page')) || 1;
  const [activePage, setActivePage] = useState(queryPage);

  const paginationPageCount = !productsCount ? 1 : Math.ceil(productsCount / DEFAULT_PRODUCTS_PER_PAGE);
  const paginationIsVisible = paginationPageCount > 1;
  const loadMoreIsVisible = paginationIsVisible && paginationPageCount > activePage;
  // Limitations of OpenSearch:
  // - May struggle with very large datasets, causing performance issues.
  // - Fuzzy search can be resource-intensive, leading to slower query times.
  // - Requires careful tuning of indexing and query parameters to balance accuracy and performance.
  // - Less effective with highly structured or exact-match data needs.
  const fuzzySearchParam = isFuzzy ? 'true' : undefined;

  useEffect(() => {
    /**
     * reset data
     * after changing query params
     * to reinitialize load more functionality
     */
    setLoadMoreProducts([]);
    setActivePage(queryPage);
  }, [query, queryPage]);

  const handleLoadMore = async () => {
    setLoadMoreIsFetching(true);
    const newPage = activePage + 1;

    const searchString = makeSearchString(
      {
        ...getQueryWithMergedFilterParams({ ...query, firstLevelFilter }),
        page: newPage.toString(),
        category_id: categoryId,
        per_page: `${DEFAULT_PRODUCTS_PER_PAGE}`,
        sort,
        fuzzy: fuzzySearchParam,
      },
    );

    const response = await fetchDataHandler({
      language,
      searchString,
    });

    if (response.status === 'success') {
      setActivePage(newPage);
      setLoadMoreProducts([...loadMoreProducts, ...response.data.results]);
    }

    setLoadMoreIsFetching(false);
  };

  const handlePaginationClick = ({ selected }: { selected: number }) => {
    const newPage = selected + 1;

    const { page, lang, categoryId, sort, ...restQuery } = query;

    const searchString = makeSearchString({
      ...restQuery,
      sort,
      page: newPage > 1 ? `${newPage}` : undefined,
      fuzzy: fuzzySearchParam,
    });

    router.push(`${pathname}${searchString}`);
  };

  const hrefPaginationBuilder = (pageNumber: number) => {
    return pageNumber > 1 ? `${pathname}?page=${pageNumber}` : pathname as string;
  };

  const paginationProps: PaginationProps = {
    pageCount: paginationPageCount,
    marginPagesDisplayed: 1,
    pageRangeDisplayed: 5,
    forcePage: queryPage - 1,
    disableInitialCallback: true,
    onPageChange: handlePaginationClick,
    hrefBuilder: hrefPaginationBuilder,
  };
  const place: ProductPlace = 'category';
  const productsToShow = [...products, ...loadMoreProducts]
    .map<CatalogProductTileProps>(product => ({
      product,
      place,
      isFavorite: getProductIsFavorite(product.eans),
      onFavoriteClick: () => toggleFavorite({ product, place }),
    }));

  return {
    handleLoadMore,
    paginationIsVisible,
    loadMoreIsVisible,
    loadMoreIsFetching,
    productTiles: productsToShow,
    paginationProps,
  };
};

export default useLoadMoreAndPagination;
