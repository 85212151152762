import { UniversalObject } from "globals";
import { FIRST_LEVEL_FILTER_QUERY_SEPARATOR } from "src/data/constants";

const getQueryWithMergedFilterParams = (query: UniversalObject) => {
  const modifiedQuery = { ...query };
  const firstLevelFilter = modifiedQuery.firstLevelFilter;

  if(firstLevelFilter) {
    const [type, name] = firstLevelFilter.split(FIRST_LEVEL_FILTER_QUERY_SEPARATOR);
    delete modifiedQuery.firstLevelFilter;

    if(!modifiedQuery[type]) {
      modifiedQuery[type] = name;
    } else {
      modifiedQuery[type] = Array.isArray(modifiedQuery[type])
        ? [name, ...modifiedQuery[type]]
        : [name, modifiedQuery[type]];
    }
  }

  return modifiedQuery;
};

export default getQueryWithMergedFilterParams;
