import React, { ReactElement, ReactNode } from 'react';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { StylishBoxStyles } from 'src/ui-kit/StylishBox/types';
import { useTheme } from "src/ui-kit/theme";
import getDataStatus from 'src/utils/getDataStatus';

export interface TabProps {
  title: string;
  children: ReactNode;
  dataMarker?: string;
  sbs?: StylishBoxStyles;
}

export interface TabsPanelProps {
  activeTabIndex: number;
  onTabClick(index: number): void;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  fullWidth?: boolean;
  sbs?: StylishBoxStyles;
}

const TabsPanel = (props: TabsPanelProps) => {
  const { children, activeTabIndex = 0, onTabClick, fullWidth, sbs } = props;
  const childrenArray = React.Children.toArray(children) as ReactElement<TabProps>[];
  const theme = useTheme();

  return (
    <StylishBox className='TabsPanel' sbs={sbs}>
      <StylishBox
        className='TabsButtons'
        sbs={{
          borderBottom: `${theme.base.border.v2} ${theme.base.color.gray300}`,
          mb: '24px',
          display: {
            phone: 'flex',
          },
        }}
      >
        {
          childrenArray.map((tab, index) => {
            const isActive = index === activeTabIndex;
            const {
              dataMarker,
              title,
            } = tab.props;

            const buttonDataMarker = dataMarker ? `${dataMarker} button` : undefined;

            return (
              <StylishBox
                dataMarker={buttonDataMarker}
                key={`tab button ${index}`}
                element='button'
                className='TabsPanelButton'
                sbs={{
                  position: 'relative',
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  p: '8px 24px',
                  border: '0',
                  fontSize: '16px',
                  fontWeight: isActive ? '600' : '400',
                  color: isActive ? theme.base.color.primary : theme.base.color.gray600,
                  width: fullWidth ? 100 / childrenArray.length + '%' : 'auto',
                }}
                elementProps={{
                  onClick: () => onTabClick(index),
                  "data-status": getDataStatus(isActive),
                }}
              >
                {title}
                {isActive && (
                  <StylishBox
                    element='span'
                    sbs={{
                      position: 'absolute',
                      left: '0',
                      bottom: '-2px',
                      width: '100%',
                      height: '2px',
                      backgroundColor: theme.base.color.primary,
                    }}
                    dataTestId='active-line'
                  />
                )}
              </StylishBox>
            );
          })
        }
      </StylishBox>

      {childrenArray[activeTabIndex]}
    </StylishBox >
  );
};

export default TabsPanel;

