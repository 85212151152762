import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { FIRST_LEVEL_FILTER_QUERY_SEPARATOR } from 'src/data/constants';
import { useRouterWithLoading } from 'src/modules/global-loading';
import { createFilterParam } from 'src/utils/catalog/createFilterParam';
import makeSearchString from 'src/utils/system/makeSearchString';
import { getArrayFromSearchParams, getQueryObjectFromSearchParams } from 'src/utils/system/searchParamsHelpers';
import getQueryWithMergedFilterParams from '../../../utils/catalog/getQueryWithMergedFilterParams';
import filterSearchParamsArr from '../utils/filterSearchParamsArr';
import getSelectedFiltersFromQuery from '../utils/getSelectedFiltersFromQuery';
import toggleQueryFilter, { NOT_MULTI_FILTER_PARAMS } from '../utils/toggleQueryFilter';

const firstLevelFilterSegmentIndex = 4;

// Хук для обновления параметров запроса
const useUpdateQueryParameter = () => {
  const router = useRouterWithLoading();
  const searchParams = useSearchParams();
  const params = useParams<{ firstLevelFilter: string }>();
  const query = getQueryObjectFromSearchParams(searchParams);
  const pathname = usePathname();
  const firstLevelFilter = params?.firstLevelFilter;
  const { page, ...restQuery } = query;

  const queryWithMergedFilterParams = getQueryWithMergedFilterParams({ ...query, firstLevelFilter });
  const selectedQueryFilters = getSelectedFiltersFromQuery(queryWithMergedFilterParams);

  // Функция для обновления параметра запроса
  const toggleInQueryString = (type: string, name: string = '') => {
    const newQuery = toggleQueryFilter(type, name, restQuery);
    const searchParams = makeSearchString(newQuery);

    router.push(`${pathname}${searchParams}`);
  };

  // Функция для добавления фильтра в сегмент URL
  const addFilterSegment = (type: string, name: string) => {
    const newFilterParam = createFilterParam(type, name, FIRST_LEVEL_FILTER_QUERY_SEPARATOR);
    const newQuery = { ...restQuery };
    const newParams = makeSearchString(newQuery);
    const newAsPath = `${pathname}${newFilterParam}/${newParams}`;

    router.push(newAsPath);
  };

  // Функция для обновления сегмента фильтра в зависимости от параметров запроса
  const updateSegmentWithQuery = () => {
    const searchParamsArr = getArrayFromSearchParams(searchParams);
    const filterParamsArr = filterSearchParamsArr(searchParamsArr, NOT_MULTI_FILTER_PARAMS);

    if (filterParamsArr.length > 0) {
      updateSegmentWithAvailableFilters(filterParamsArr);
    } else {
      clearFilterSegment();
    }
  };

  // Функция для обновления сегмента фильтра с доступными фильтрами
  const updateSegmentWithAvailableFilters = (filterParamsArr: string[]) => {
    // Есть фильтры, которые можно использовать в сегменте
    const firstFilterParam = filterParamsArr[0];
    const [newType, newName] = decodeURIComponent(firstFilterParam).split('=');
    const newQuery = toggleQueryFilter(newType, newName, restQuery);
    newQuery.firstLevelFilter = firstFilterParam.replace('=', FIRST_LEVEL_FILTER_QUERY_SEPARATOR);
    const newSearchParams = makeSearchString(newQuery);

    const segments = pathname?.split('/') || [];
    segments[firstLevelFilterSegmentIndex] = newQuery.firstLevelFilter;
    const newPath = segments.join('/');

    router.push(`${newPath}${newSearchParams}`);
  };

  // Функция для очистки сегмента фильтра
  const clearFilterSegment = () => {
    const newQuery = { ...restQuery };
    delete newQuery.firstLevelFilter;

    const segments = pathname?.split('/') || [];
    const newPath = segments.slice(0, firstLevelFilterSegmentIndex).join('/');
    const newSearchParams = makeSearchString(newQuery);

    router.push(`${newPath}/${newSearchParams}`);
  };

  // Функция для обновления фильтра в сегменте URL
  function toggleWithFilterSegmentOfURL(
    type: string,
    name: string,
  ) {
    if (!firstLevelFilter) {
      // Если сегмента фильтра нет, добавляем его
      addFilterSegment(type, name);
    } else {
      // Если сегмент фильтра присутствует, обновляем его
      const convertedFromSegmentToParams = firstLevelFilter.replace(FIRST_LEVEL_FILTER_QUERY_SEPARATOR, '=');
      const isNewFilterEqualSegmentFilter = convertedFromSegmentToParams === `${type}=${name}`;

      if (!isNewFilterEqualSegmentFilter) {
        // Если фильтр изменился
        const newQuery = toggleQueryFilter(type, name, restQuery);
        const searchParams = makeSearchString(newQuery);

        router.push(`${pathname}${searchParams}`);
      } else {
        updateSegmentWithQuery();
      }
    }
  }

  // Функция для очистки всех параметров запроса, кроме 'lang'
  // так же опциональных параметров 'categoryId' и 'q'
  const clearAllQueryParameters = () => {
    const qParam = searchParams?.get('q');
    const qParamString = qParam ? `?q=${qParam}` : '';

    const newPathname = !!firstLevelFilter ? pathname?.replace(firstLevelFilter as string, '') : pathname;
    router.push(`${newPathname}${qParamString}`);
  };

  // Функция для удаления последнего фильтра
  const removeLastFilter = () => {
    const searchParamsArr = getArrayFromSearchParams(searchParams);
    const filterParams = filterSearchParamsArr(searchParamsArr);
    const lastFilterParam = filterParams[filterParams.length - 1];
    const [type] = decodeURIComponent(lastFilterParam).split('=');

    updateQueryParameter(type);
  };

  // Функция для обновления параметра запроса в зависимости от текущего сегмента URL
  const updateQueryParameter = (key: string, value: string = '') => {
    const segments = pathname?.split('/') || [];

    if (segments[2] === 'categories' && key !== 'price') {
      // Обработка для сегмента категорий
      toggleWithFilterSegmentOfURL(key, value);
    } else {
      // Обработка для других сегментов
      toggleInQueryString(key, value);
    }
  };

  return {
    selectedQueryFilters,
    removeLastFilter,
    updateQueryParameter,
    clearAllQueryParameters,
  };
};

export default useUpdateQueryParameter;
