import Stack from 'src/ui-kit/Stack/Stack';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import SliderButton from '../SliderButton/SliderButton';

export interface Controls {
  onPrevSlide: () => void;
  onNextSlide: () => void;
  sliderState: {
    isEndPositionLeft: boolean;
    isEndPositionRight: boolean;
  };
}

interface NavButtonsProps {
  controls: Controls;
  dataMarker?: string;
}

const NavButtons = (props: NavButtonsProps) => {
  const { dataMarker, controls: { onPrevSlide, onNextSlide, sliderState } } = props;
  const { isEndPositionLeft, isEndPositionRight } = sliderState;

  return (
    <Stack className='NavButtons' sbs={{ flex: '0 0 auto' }} dataMarker={dataMarker}>
      <StylishBox sbs={{ ml: '10px' }}>
        <SliderButton variant='prev' disabled={isEndPositionLeft} onClick={onPrevSlide} />
      </StylishBox>
      <StylishBox sbs={{ ml: '10px' }}>
        <SliderButton variant='next' disabled={isEndPositionRight} onClick={onNextSlide} />
      </StylishBox>
    </Stack>
  );
};

export default NavButtons;
