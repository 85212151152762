import useLocalize from "src/services/localize/useLocalize";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { ActiveFilter } from "src/utils/catalog/getActiveFilters";
import useUpdateQueryParameter from "../../hooks/useUpdateQueryParameter";
import CatalogActiveFiltersList from "../CatalogActiveFiltersList/CatalogActiveFiltersList";
import CatalogEmptySection from "../CatalogEmptySection/CatalogEmptySection";

interface CatalogFiltersEmptyProps {
  activeFilters: ActiveFilter[];
}

const CatalogFiltersEmpty = ({ activeFilters }: CatalogFiltersEmptyProps) => {
  const localize = useLocalize();

  const { removeLastFilter, clearAllQueryParameters, updateQueryParameter } = useUpdateQueryParameter();

  return (
    <StylishBox dataMarker="CatalogFiltersEmpty">
      <CatalogActiveFiltersList
        activeFilters={activeFilters}
        onClearAll={clearAllQueryParameters}
        onDeleteItem={updateQueryParameter}
      />

      <CatalogEmptySection
        buttonProps={{
          children: localize('products.empty.button'),
          onClick: removeLastFilter,
          dataMarker: 'Remove Last Filter Button',
        }}
      />
    </StylishBox>
  );
};

export default CatalogFiltersEmpty;
