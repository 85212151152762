import styled from '@emotion/styled';
import { HEADER_HEIGHT, HEADER_HEIGHT_PHONE, FILTERS_PANEL_HEIGHT } from 'src/data/constants';
import { phone, tablet } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CatalogPageLayoutStyled = styled.main<StyledThemeProps>(({ theme }) => `
  background: ${theme.base.color.gray100};
  padding-top: ${HEADER_HEIGHT}px;

  .CatalogPageLayout__container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 32px 32px 0;
  }

  @media ${tablet} {
    &.CatalogPageLayout_withFilters {
      padding-top: ${HEADER_HEIGHT + FILTERS_PANEL_HEIGHT}px;
    }
  }

  @media ${phone} {
    padding-top: ${HEADER_HEIGHT_PHONE};

    .CatalogPageLayout__container {
      padding-left: 16px;
      padding-right: 16px;
    }

    &.CatalogPageLayout_withFilters {
      padding-top: ${HEADER_HEIGHT_PHONE + FILTERS_PANEL_HEIGHT}px;
    }
  }
`);
