/* eslint-disable @next/next/no-img-element */

import cn from 'classnames';
import { SyntheticEvent, useState } from 'react';
import useCursorPosition from '../../hooks/useCursorPosition';
import styles from './ZoomableImageSwitcher.styles';
import { Dimensions, Position } from '../../types';

export interface ZoomableImageSwitcherProps {
  smallImageSrc: string;
  largeImageSrc: string;
  alt: string;
  title: string;
  safetyBorder?: number;
  withHalfOpacity?: boolean;
}

const ZoomableImageSwitcher = (props: ZoomableImageSwitcherProps) => {
  const {
    safetyBorder = 0,
    withHalfOpacity,
    largeImageSrc,
    smallImageSrc,
    alt,
    title,
  } = props;

  const {
    ref: imageWrapperRef,
    dimensions: {
      width: containerWidth,
      height: containerHeight,
    },
    position: {
      x,
      y,
    },
    isActive,
  } = useCursorPosition();

  const [maxTranslations, setMaxTranslations] = useState<Position | null>(null);
  const [largeImageDimensions, setLargeImageDimensions] = useState<Dimensions | null>(null);

  const handleLargeImageLoad = (
    event: SyntheticEvent<HTMLImageElement> & { target: HTMLImageElement },
  ) => {
    const largeImageWidth = event.target.clientWidth;
    const largeImageHeight = event.target.clientHeight;

    const maxTranslations: Position = {
      x: largeImageWidth - containerWidth,
      y: largeImageHeight - containerHeight,
    };

    setMaxTranslations(maxTranslations);

    setLargeImageDimensions({
      width: largeImageWidth,
      height: largeImageHeight,
    });
  };

  const getCorrectedTranslationValue = (
    val: number, min: number, max: number,
  ) => {
    if (val < min) {
      return min;
    }

    if (val > max) {
      return max;
    }

    return val;
  };

  const getLargeImageTranslations = () => {
    if (!largeImageDimensions || !maxTranslations) {
      return `translate3D(0, 0, 0)`;
    }

    const activeContainerWidth = containerWidth - safetyBorder * 2;
    const activeContainerHeight = containerHeight - safetyBorder * 2;

    const activeX = x - safetyBorder;
    const activeY = y - safetyBorder;

    const desiredX = activeX / activeContainerWidth * maxTranslations.x;
    const desiredY = activeY / activeContainerHeight * maxTranslations.y;
    const finalX = getCorrectedTranslationValue(desiredX, 0, maxTranslations.x);
    const finalY = getCorrectedTranslationValue(desiredY, 0, maxTranslations.y);

    return `translate3D(-${finalX}px, -${finalY}px, 0)`;
  };

  return (
    <div
      className='ZoomableImageSwitcher'
      ref={imageWrapperRef}
    >
      <img
        src={smallImageSrc}
        alt={alt}
        title={title}
        className={cn(
          'ZoomableImageSwitcher__smallImg', {
          'ZoomableImageSwitcher__smallImg_halfOpacity': withHalfOpacity,
        })}
      />
      {
        isActive && (
          <img
            src={largeImageSrc}
            alt={alt}
            title={title}
            className='ZoomableImageSwitcher__largeImg'
            onLoad={handleLargeImageLoad}
            style={
              {
                transform: getLargeImageTranslations(),
                transformOrigin: '50% 50%',
              }
            }
          />
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default ZoomableImageSwitcher;
