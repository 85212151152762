import { UberCatalogProduct } from "src/api/types";
import { imageSizes } from "../../fixtures";
import { GalleryItem } from "src/modules/gallery";

export const getGalleryItemsFromProductImages = (productImages: UberCatalogProduct['gallery'], altBase: string, titleBase: string): GalleryItem[] => (
  productImages.map((item, index) => ({
    srcSet: {
      small: item[imageSizes.preview],
      medium: item[imageSizes.medium],
      big: item[imageSizes.large],
    },
    alt: `${altBase} ${index + 1}`,
    title: titleBase,
  }))
);
