import { ReactNode } from "react";
import { Typography } from "src/ui-kit/Typography";

interface CategoryTitleProps {
  title: ReactNode;
}

const CategoryTitle = ({ title }: CategoryTitleProps) => {
  if (!title) {
    return null;
  };

  return (
    <Typography
      element="h1"
      variant={{
        general: 'h2',
        phone: 'h3',
      }}
    >
      {title}
    </Typography>
  );
};

export default CategoryTitle;
