import cn from 'classnames';
import Image from 'next/image';
import { useState } from 'react';
import Slider from 'src/components/SliderV2';
import { useSlider } from 'src/components/SliderV2/helpers/useSlider';
import { CustomNavigationSliderV2Props } from 'src/components/SliderV2/types';
import { useTheme } from 'src/ui-kit/theme';
import { GalleryItem } from '../../types';
import ThumbsNavigationControls from '../ThumbsNavigationControls/ThumbsNavigationControls';
import { ThumbsNavigationStyled } from './ThumbsNavigation.styles';
import Responsive from 'src/components/Responsive';

interface Props extends CustomNavigationSliderV2Props {
  items: GalleryItem[];
  withControls?: boolean;
}

const countOfImagesInViewport = 3;

const ThumbsNavigation = (props: Props) => {
  const { controls: { onGoToSlide, sliderState }, items, withControls = true } = props;

  const theme = useTheme();

  const { sliderRef, sliderControls, controls } = useSlider({});
  const [controlsAreShown, setControlsAreShown] = useState(false);

  const onMouseEnter = () => {
    if (items.length > countOfImagesInViewport && withControls) {
      setControlsAreShown(true);
    }
  };

  const onMouseLeave = () => {
    setControlsAreShown(false);
  };

  const handleButtonClick = (i: number) => {
    if (typeof onGoToSlide === 'function') {
      onGoToSlide(i);
    }
  };

  return (
    <ThumbsNavigationStyled
      theme={theme}
      data-marker="Gallery thumbs"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className='ThumbsNavigation'
    >
      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
      >
        {items.map((slide, i) => (
          <div key={i}
            className={cn(
              'ThumbsNavigation__slide', {
              'ThumbsNavigation__slide_active': i === sliderState?.currentPosition,
            },
            )}
            onClick={() => handleButtonClick(i)}
          >
            <div className="ThumbsNavigation__imageWrapper">
              <Image
                src={slide.srcSet.small || slide.srcSet.medium}
                alt={slide.alt}
                title={slide.title || slide.alt}
                fill
              />
            </div>
          </div>
        ))}
      </Slider>
      <Responsive displayOn='desktop'>
        {
          controls && controlsAreShown && <ThumbsNavigationControls controls={controls} />
        }
      </Responsive>
    </ThumbsNavigationStyled>
  );
};

export default ThumbsNavigation;
