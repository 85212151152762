import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const BadgeStyled = styled.div<StyledThemeProps>(({ theme }) => `
    font-size: 13px;
    line-height: 21px;
    pointer-events: none;
    line-height: normal;
    border-radius: 32px;
    border: 1.5px solid ${theme.base.color.white};
    width: 58px;
    height: 29px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }
  &.Badge_bundle {
    background-color: ${theme.base.color.primary};
    color: ${theme.base.color.white};
    text-transform: lowercase;
  }
  &.Badge_straightLeft {
    font-size: 13px;
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`);
