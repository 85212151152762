import dynamic from 'next/dynamic';
import { MouseEvent, useState } from 'react';
import Responsive from 'src/components/Responsive';
import Slider from 'src/components/SliderV2';
import { useSlider } from 'src/components/SliderV2/helpers/useSlider';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { defaultProductImage } from '../../../../fixtures';
import { GalleryItem } from '../../types';
import BulletsNavigation from '../BulletsNavigation/BulletsNavigation';
import NotZoomableImage from '../NotZoomableImage/NotZoomableImage';
import ThumbsNavigation from '../ThumbsNavigation/ThumbsNavigation';
import ZoomableImageSwitcher from '../ZoomableImageSwitcher/ZoomableImageSwitcher';

export interface ImagesGalleryProps {
  items: GalleryItem[];
  withHalfOpacity?: boolean;
  withZoom?: boolean;
}

const ZoomModal = dynamic(
  () => import('../ZoomModal/ZoomModal'),
  { ssr: false },
);

const ImagesGallery = (props: ImagesGalleryProps) => {
  const {
    items,
    withZoom = true,
    withHalfOpacity,
  } = props;

  const { sliderRef, controls, sliderControls } = useSlider({
    withSwiperMode: true,
  });
  const [zoomModalIsOpened, setZoomModalIsOpened] = useState(false);

  const hasImage = !!items.length;

  const openZoomModal = (event: MouseEvent) => {
    event.stopPropagation();

    if (withZoom) {
      setZoomModalIsOpened(true);
    }
  };

  const closeZoomModal = () => {
    setZoomModalIsOpened(false);
  };

  if (!hasImage) {
    return (
      <NotZoomableImage
        imageSrc={defaultProductImage}
        alt='default image'
      />
    );
  }

  return (
    <StylishBox className='ImagesGallery'>
      <Slider
        sliderRef={sliderRef}
        controls={sliderControls}
      >
        {items.map((image) => (
          <StylishBox
            key={image.alt}
            dataMarker='Gallery_slide'
            elementProps={{
              onClick: openZoomModal,
            }}
            sbs={{
              position: 'relative',
              flexShrink: '0',
              width: '100%',
            }}
          >
            <Responsive displayOn='desktop'>
              {withZoom
                ? <ZoomableImageSwitcher
                    smallImageSrc={image.srcSet.medium}
                    largeImageSrc={image.srcSet.big}
                    alt={image.alt}
                    title={image.title || image.alt}
                    safetyBorder={30}
                    withHalfOpacity={withHalfOpacity}
                  />
                : <NotZoomableImage
                    imageSrc={image.srcSet.medium}
                    alt={image.alt}
                    title={image.title}
                  />
              }
            </Responsive>
            <Responsive displayOn='phoneTablet'>
              <NotZoomableImage
                imageSrc={image.srcSet.medium}
                alt={image.alt}
                title={image.title}
              />
            </Responsive>
          </StylishBox>
        ))}
      </Slider>
      <StylishBox sbs={{
        height: {
          phone: 'auto',
          general: '55px',
        },
        mt: {
          phone: '10px',
          general: '20px',
        },
      }}>
        <Responsive displayOn='tabletDesktop'>
          <ThumbsNavigation controls={controls} items={items} />
        </Responsive>
        <Responsive displayOn='phone'>
          <BulletsNavigation controls={controls} count={items.length} />
        </Responsive>
      </StylishBox>

      {zoomModalIsOpened && (
        <ZoomModal
          items={items}
          initialPosition={controls.sliderState.currentPosition}
          onClose={closeZoomModal}
        />
      )}
    </StylishBox>
  );
};

export default ImagesGallery;
