import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import typography from '../../../../styles/typography';

export const SeoTextStyled = styled.div<StyledThemeProps>(({ theme }) => `
  margin-top: 72px;

  .SeoText__wrapper {
    position: relative;
    max-height: none;
  }

  .SeoText__content * + * {
    margin: 24px 0 0;
  }

  .SeoText__content h1 {
    ${typography.h1}
  }

  .SeoText__content h2 {
    ${typography.h2}
  }

  .SeoText__content h3 {
    ${typography.h3}
  }

  .SeoText__content h4 {
    ${typography.h4}
  }

  .SeoText__content h5 {
    ${typography.h5}
  }

  .SeoText__content h6 {
    ${typography.h6}
  }

  .SeoText__content * + h1,
  .SeoText__content * + h2,
  .SeoText__content * + h3,
  .SeoText__content * + h4,
  .SeoText__content * + h5,
  .SeoText__content * + h6 {
    margin : 40px 0 0;
  }

  .SeoText__content img + h1,
  .SeoText__content img + h2,
  .SeoText__content img + h3,
  .SeoText__content img + h4,
  .SeoText__content img + h5,
  .SeoText__content img + h6 {
    margin : 32px 0 0;
  }

  .SeoText__content p {
    ${typography.text}
  }

  .SeoText__content h3 + p {
    margin-top: 16px;
  }

  .SeoText__content ol {
    padding: 0;
    margin: 24px 0 0;
    list-style-position: inside;
  }

  .SeoText__content ul {
    list-style: none;
    padding: 0;
    margin: 24px 0 0;
  }

  .SeoText__content li {
    ${typography.text}
  }

  .SeoText__content ol > li {
    margin: 0;
  }

  .SeoText__content ul > li {
    margin: 8px 0 0;
  }

  .SeoText__content ul > li:before {
    content: '';
    display:inline-block;
    width: 6px;
    height: 6px;
    margin: 0 8px 1px 0;

    border: 1px solid ${theme.base.color.primary};
    border-radius: 50%;

    vertical-align: baseline;
  }

  .SeoText__content img {
    display: block;
    max-width: 100%;
    max-height: 240px;
    object-fit: contain;
    margin: 40px 0 32px;
  }
`);
