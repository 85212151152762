import { Filter } from "src/api/types";
import { ActiveFilter } from "src/utils/catalog/getActiveFilters";
import getFilterOptionIsChecked from "../../utils/getFilterOptionIsChecked";
import CheckListItem from "../CheckList/CheckListItem/CheckListItem";
import { HandleFilterFunc } from "../CheckList/useCheckList";
import CatalogFiltersMobileCategoriesButton from "./CatalogFiltersMobileCategoriesButton";
import { CatalogFiltersMobileCategoriesContentStyled } from "./CatalogFiltersMobileCategoriesContent.styles";

interface CatalogFiltersMobileCategoriesContentProps {
  filters: Filter[];
  activeFilters: ActiveFilter[];
  openCategory(filter: Filter): void;
  handleFilter: HandleFilterFunc;
  selectedQueryFilters: string[];
}

const CatalogFiltersMobileCategoriesContent = (props: CatalogFiltersMobileCategoriesContentProps) => {

  const {
    filters,
    activeFilters,
    openCategory,
    handleFilter,
    selectedQueryFilters,
  } = props;

  return (
    <CatalogFiltersMobileCategoriesContentStyled className='CatalogFiltersMobileCategoriesContent'>
        {filters.map(filter => {
          if (filter.type === 'single' && filter.options.length > 0) {
            const option = filter.options[0];
            const isChecked = getFilterOptionIsChecked(selectedQueryFilters, filter.key, option.value);

            return (
              <div
                className='CatalogFiltersMobileCategoriesContent__item'
                key={option.value}
              >
                <CheckListItem
                  filter={filter}
                  option={option}
                  onClick={handleFilter}
                  fullWidth
                  isChecked={isChecked}
                />
              </div>
            );
          }

          return (
            <CatalogFiltersMobileCategoriesButton
              key={filter.key}
              filter={filter}
              activeFilters={activeFilters}
              onClick={openCategory}
            />
          );
        })}
      </CatalogFiltersMobileCategoriesContentStyled>
  );
};

export default CatalogFiltersMobileCategoriesContent;
