import { ParsedUrlQuery } from 'querystring';

export const NOT_MULTI_FILTER_PARAMS = [
  'sort',
  'page',
  'q',
  'per_page',
  'admitad_uid',
  'aff_sub',
  'mobile_app_offer',
  'gclid',
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_id',
  'utm_term',
  'utm_content',
  'variant',
  'price',
  'delivery',
  'api',
];

const toggleQueryFilter = (key: string, value: string, query: ParsedUrlQuery) => {
  // Копируем текущие параметры запроса
  const updatedQuery = { ...query };

  const processMultiKeys = (key: string, value: string) => {
    let values: string[] = updatedQuery[key]
      ? Array.isArray(updatedQuery[key])
        ? (updatedQuery[key] as string[])
        : [updatedQuery[key] as string]
      : [];

    if (value && !values.includes(value)) {
      values.push(value);
    } else {
      values = values.filter(v => v !== value);
    }

    values.length > 0 ? updatedQuery[key] = values : delete updatedQuery[key];
  };

  const queryValue = query[key] === value ? undefined : value;

  if (queryValue) {
    if (NOT_MULTI_FILTER_PARAMS.includes(key)) {
      updatedQuery[key] = value;
    } else {
      processMultiKeys(key, value);
    }
  } else {
    delete updatedQuery[key];
  }

  return updatedQuery;
};

export default toggleQueryFilter;
