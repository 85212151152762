import { ParamsObject } from 'src/app/types';
import getQueryWithMergedFilterParams from 'src/utils/catalog/getQueryWithMergedFilterParams';


const excludeParams = ['lang', 'categoryId'];

/**
 * Checks if the page should be closed from indexing based on the query parameters.
 * @param query The parsed URL query parameters.
 * @returns A boolean flag indicating whether the page should be closed from indexing.
 */

interface Params {
  searchParams: ParamsObject;
  pageParams: ParamsObject | null;
}

const checkCategoryPageIsClosedFromIndexing = ({ pageParams, searchParams }: Params): boolean => {
  const filterBlocks: Record<string, string[]> = {};
  let totalFilters = 0;

  const queryWithMergedFilterParams = getQueryWithMergedFilterParams({...pageParams, ...searchParams});

  // Iterate over the query parameters
  Object.entries(queryWithMergedFilterParams).forEach(([key, value]) => {

    if (!excludeParams.includes(key)) {
      // Convert value to an array if it is not already, and filter out undefined values
      const values = Array.isArray(value) ? value.filter((v): v is string => !!v) : [value].filter((v): v is string => !!v);

      // Initialize the filter block if it doesn't exist
      if (!filterBlocks[key]) {
        filterBlocks[key] = [];
      }

      // Add values to the filter block
      filterBlocks[key] = filterBlocks[key].concat(values);

      // Increment the total filters count
      totalFilters += values.length;
    }
  });

  // Check if any filter block has two or more values
  const multipleFiltersInBlock = Object.values(filterBlocks).some(values => values.length >= 2);

  // Check if total filters are four or more
  const tooManyFilters = totalFilters >= 4;

  // Return true if either condition is met
  return multipleFiltersInBlock || tooManyFilters;
};

export default checkCategoryPageIsClosedFromIndexing;
