import Image from 'next/image';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';

interface Props {
  imageSrc: string;
  alt: string;
  title?: string;
}

const NotZoomableImage = (props: Props) => {
  const { imageSrc, alt, title } = props;

  return (
    <StylishBox className='NotZoomableImage' sbs={{
      position: 'relative',
      width: '100%',
      pb: '100%',
    }}>
      <Image
        src={imageSrc}
        alt={alt}
        title={title || alt}
        fill
      />
    </StylishBox>
  );
};

export default NotZoomableImage;
