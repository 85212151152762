import { getCategoriesUrl } from "./getCategoriesUrl";

interface Params {
  lang: string;
  activeSort?: string | null;
  categoryId: string;
}

export const getCategoryHrefWithSort = ({ lang, activeSort, categoryId }: Params) => {
  const sortQuery = activeSort ? `?sort=${activeSort}` : '';

  return getCategoriesUrl({
    lang,
    categoryId,
    query: sortQuery,
  });
};
