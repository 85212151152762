import { CSSProperties } from "react";
import Radio from "src/UIKitOld/Radio/Radio";
import GeneralModal from "src/components/GeneralModal/GeneralModal";
import useLocalize from "src/services/localize/useLocalize";
import { Typography } from "src/ui-kit/Typography";
import { useTheme } from "src/ui-kit/theme";
import { SortOption } from "src/utils/catalog/sortContent";
import { ContentSortModalStyled } from "./ContentSortModal.styles";
import { useCatalogSorting } from "src/utils/catalog/useCatalogSorting";

export interface ContentSortModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContentSortModal = (props: ContentSortModalProps) => {
  const { isOpen, onClose } = props;
  const localize = useLocalize();

  const { selectedSortItem, sortList, onSort } = useCatalogSorting();

  const handleChooseSort = (data: SortOption) => {
    if (data.value !== selectedSortItem.value) {
      onSort(data);
    }

    onClose();
    setTimeout(() => window.scrollTo(0,0), 0);
  };

  const theme = useTheme();

  const customStyles: {
    [key: string]: CSSProperties;
  } = {
    overlay: {
      background: 'rgba(51,51,51,0.45)',
      zIndex: theme.base.zIndex.modalOverlay,
      display: 'flex',
      overflowY: 'auto',
    },
    content: {
      width: '100%',
      outline: 'none',
      marginTop: 'auto',
      position: 'relative',
    },
  };

  return (
    <GeneralModal
      isOpen={isOpen}
      onClose={onClose}
      contentStyles={customStyles.content}
      overlayStyles={customStyles.overlay}
      withCloseButton
    >
      <ContentSortModalStyled
        theme={theme}
        className='ContentSortModal'
      >
        <div className='ContentSortModal__title'>
          <Typography variant="text5" sbs={{ lineHeight: '21px' }}>
            {localize('filters.what-to-show')}
          </Typography>
        </div>
        <div className='ContentSortModal__optionList'>
          {sortList.map(item => (
            <div
              className='ContentSortModal__option'
              key={item.value}
            >
              <Radio
                checked={item.value === selectedSortItem.value}
                name='content-sort'
                value={item.value}
                dataMarkerValue={item.value}
                onClick={() => handleChooseSort(item)}
              >
                {localize(item.translation_key)}
              </Radio>
            </div>
          ))}
        </div>
      </ContentSortModalStyled>
    </GeneralModal>
  );
};

export default ContentSortModal;
