import cn from 'classnames';
import { useTheme } from 'src/ui-kit/theme/UseTheme';
import getDataStatus from 'src/utils/getDataStatus';
import IconFont from '../../ui-kit/IconFont';
import { CheckboxStyled } from './Checkbox.styles';

interface Props {
  checked?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  labelledby?: string;
  dataTestId?: string;
  onClick?(): void;
  dataMarker?: string;
}

export default function Checkbox(props: Props) {
  const { checked, hovered, labelledby, dataTestId, disabled, onClick, dataMarker = 'Checkbox'} = props;
  const theme = useTheme();

  return (
    <CheckboxStyled
      theme={theme}
      onClick={onClick}
      aria-checked={checked}
      aria-disabled={disabled}
      aria-labelledby={labelledby}
      role='checkbox'
      data-marker={dataMarker}
      data-status={getDataStatus(!!checked)}
      data-testid={dataTestId}
      className={cn(
        'Checkbox', {
        'Checkbox_checked': checked,
        'Checkbox_hovered': hovered,
        'Checkbox_disabled': disabled,
      })}
    >
      {checked && (
        <IconFont
          name='check'
          color='white'
          size='10px'
          elementProps={{ role: 'presentation' }}
        />
      )}
    </CheckboxStyled>
  );
}
