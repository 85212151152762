import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";

export interface Props {
  variant: 'prev' | 'next';
  onClick(): void;
  disabled: boolean;
}

const SliderButton = (props: Props) => {
  const { variant, onClick, disabled } = props;
  const theme = useTheme();

  return (
    <StylishBox
      element="button"
      elementProps={{
        type: 'button',
        onClick,
        disabled,
      }}
      dataTestId={`${variant}-button`}
      dataMarker={variant === 'prev' ? 'Previous slide' : 'Next slide'}
      sbs={{
        width: '40px',
        height: '40px',
        backgroundColor: 'transparent',
        border: theme.base.border.v1,
        borderColor: disabled ? theme.base.color.gray300 : theme.base.color.gray400,
        borderRadius: `${theme.base.borderRadii.v1}`,
        fontSize: '10px',
        cursor: disabled ? 'default' : 'pointer',
        transition: 'border-color 0.2s ease-out',
        hover: {
          borderColor: disabled ? theme.base.color.gray300 : theme.base.color.primary,
        },
      }}
    >
      <IconFont name={variant === 'prev' ? 'arrow-small-left' : 'arrow-small-right'} />
    </StylishBox>
  );
};

export default SliderButton;
