import { useState } from "react";
import { useCatalogSorting } from "src/utils/catalog/useCatalogSorting";



const useCatalogSortMobile = () => {
  const { selectedSortItem } = useCatalogSorting();

  const [isSortingOpened, handleSortingOpened] = useState(false);

  const openSorting = () => {
    handleSortingOpened(true);
  };

  const closeSorting = () => {
    handleSortingOpened(false);
  };

  return {
    isSortingOpened,
    openSorting,
    closeSorting,
    selectedSortItem,
  };
};

export default useCatalogSortMobile;
