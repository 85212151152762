import { createFilterParam } from "src/utils/catalog/createFilterParam";

const getFilterOptionIsChecked = (selectedQueryFilters: string[], key: string, value: string) => {
  const isChecked = selectedQueryFilters.indexOf(
    createFilterParam(key, value, "="),
  ) !== -1;

  return isChecked;
};

export default getFilterOptionIsChecked;
