import Image from "next/image";
import { defaultProductImage } from "src/fixtures";
import { LinkWithLoading } from "src/modules/global-loading";
import { useTheme } from "src/ui-kit/theme";
import { CategoryCardStyled } from "./CategoryCard.styles";

interface Props {
  imgSrc: string | undefined;
  title: string;
  href: string;
  onClick(): void;
}

const CategoryCard = (props: Props) => {
  const { imgSrc, title, href, onClick } = props;
  const theme = useTheme();

  return (
    <LinkWithLoading href={href} onClick={onClick}>
      <CategoryCardStyled
        theme={theme}
        className="CategoryCard"
      >
        <div className="CategoryCard__title">
          {title}
        </div>
        <div className="CategoryCard__imgWrapper">
          <Image
            src={imgSrc || defaultProductImage}
            alt={title}
            className="CategoryCard__img"
            fill
          />
        </div>
      </CategoryCardStyled>
    </LinkWithLoading>
  );
};

export default CategoryCard;
