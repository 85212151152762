import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const BulletsNavigationStyled = styled.div<StyledThemeProps>(({ theme }) => `
  display: flex;
  justify-content: center;

  .BulletsNavigation__bullet {
    width: 5px;
    height: 5px;
    margin: 5px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: ${theme.base.color.gray300};
  }

  .BulletsNavigation__bullet_active {
    background-color: ${theme.base.color.primary};
  }
`);
