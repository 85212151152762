import classNames from 'classnames';
import ReactPlaceholder from 'react-placeholder';
import Responsive from 'src/components/Responsive';
import { CatalogActiveFiltersList, ContentSortDropdown, useUpdateQueryParameter } from 'src/modules/filters';
import useLocalize from 'src/services/localize/useLocalize';
import Button from 'src/ui-kit/Button';
import { useTheme } from 'src/ui-kit/theme';
import { ActiveFilter } from 'src/utils/catalog/getActiveFilters';
import GoodsNumber from '../../../../components/GoodsNumber/GoodsNumber';
import { CatalogSortingProps } from '../../../../utils/catalog/useCatalogSorting';
import { LoadMoreAndPaginationProps } from '../../hooks/useLoadMoreAndPaginationTypes';
import Pagination from '../Pagination/Pagination';
import ProductsTable from '../ProductsTable/ProductsTable';
import ProductTilePlaceholder from '../ProductTilePlaceholder/ProductTilePlaceholder';
import { ProductBoxStyled } from './ProductBox.styles';

export interface ProductBoxProps {
  countAvailable: number;
  activeFilters: ActiveFilter[];
  sortingProps: CatalogSortingProps;
  loadMoreAndPaginationProps: LoadMoreAndPaginationProps;
}

const ProductBox = (props: ProductBoxProps) => {
  const { countAvailable, activeFilters, sortingProps, loadMoreAndPaginationProps } = props;

  const { selectedSortItem, sortList, onSort } = sortingProps;

  const localize = useLocalize();
  const theme = useTheme();

  const {
    loadMoreIsFetching,
    loadMoreIsVisible,
    paginationIsVisible,
    productTiles,
    paginationProps,
    handleLoadMore,
  } = loadMoreAndPaginationProps;

  const { clearAllQueryParameters, updateQueryParameter } = useUpdateQueryParameter();

  return (
    <ProductBoxStyled
      className="ProductBox"
      data-marker='Products'
      theme={theme}
    >
      <div className="ProductBox__head">
        <div className="ProductBox__count" data-marker='Goods Number'>
          <GoodsNumber number={countAvailable} />
        </div>
        <Responsive displayOn="desktop">
          <ContentSortDropdown
            label={`${localize('filters.what-to-show')}:`}
            items={sortList}
            onChange={onSort}
            active={selectedSortItem}
          />
        </Responsive>
      </div>

      <CatalogActiveFiltersList
        activeFilters={activeFilters}
        onClearAll={clearAllQueryParameters}
        onDeleteItem={updateQueryParameter}
      />

      <ProductsTable productTiles={productTiles} />

      {loadMoreIsVisible && (
        <ReactPlaceholder
          ready={!loadMoreIsFetching}
          customPlaceholder={<ProductTilePlaceholder repeat={4} />}
        >
          <div className='ProductBox__loadMore'>
            <Button
              variant='primary'
              onClick={handleLoadMore}
              dataMarker='Load More'
            >
              {localize('show_more')}
            </Button>
          </div>
        </ReactPlaceholder>
      )}

      {paginationIsVisible && (
        <div className={classNames("ProductBox__pagination", {
          ProductBox__pagination_withMargin: !loadMoreIsVisible,
        })}>
          <Pagination paginationOptions={paginationProps} />
        </div>
      )}
    </ProductBoxStyled>
  );
};

export default ProductBox;
