import { LinkWithLoading } from "src/modules/global-loading";
import { useTheme } from 'src/ui-kit/theme';
import { Typography } from 'src/ui-kit/Typography';
import upperCaseFirstChar from "src/utils/js-helpers/upperCaseFirstChar";

export interface CategoryTagProps {
  title: string;
  href: string;
  isActive: boolean;
  onClick?(): void;
}

const CategoryTag = (props: CategoryTagProps) => {
  const { title, href, isActive, onClick } = props;
  const theme = useTheme();

  return (
    <LinkWithLoading href={href} onClick={onClick}>
      <Typography
        element='span'
        sbs={{
          display: 'inline-block',
          p: '4px 12px',
          border: theme.base.border.v1,
          borderColor: isActive ? theme.base.color.primary : theme.base.color.gray200,
          borderRadius: theme.base.borderRadii.v1,
          bgColor: theme.base.color.white,
          lineHeight: '24px',
          hover: {
            borderColor: theme.base.color.primary,
          },
          textTransform: 'inherit',
        }}
        variant={isActive ? 'text5' : 'text6'}
      >
        {upperCaseFirstChar(title)}
      </Typography>
    </LinkWithLoading>
  );
};

export default CategoryTag;
