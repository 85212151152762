import useLocalize from "src/services/localize/useLocalize";
import Button from "src/ui-kit/Button";
import { ButtonProps } from "src/ui-kit/Button/Button";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";

interface CatalogEmptySectionProps {
  buttonProps: Partial<ButtonProps>;
}

const CatalogEmptySection = ({ buttonProps }: CatalogEmptySectionProps) => {
  const theme = useTheme();
  const localize = useLocalize();

  return (
    <StylishBox
      sbs={{
        backgroundColor: theme.base.color.white,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '24px',
        py: {
          general: '72px',
          phone: '56px',
        },
        height: {
          general: '276px',
          phone: '224px',
        },
        width: '100%',
      }}
    >
      <IconFont
        name="filters"
        color="gray400"
        sbs={{
          fontSize: {
            general: '40px',
            phone: '32px',
          },
        }}
      />
      <Typography variant="h5" align="center" sbs={{ my: '16px' }}>
        {localize('products.empty')}
      </Typography>
      <Button
        {...buttonProps}
        variant="primary"
        sbs={{
          maxWidth: '245px',
        }}
      >
        {buttonProps.children}
      </Button>
    </StylishBox>
  );
};

export default CatalogEmptySection;
