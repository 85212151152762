import styled from '@emotion/styled';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const InputStyled = styled.div<StyledThemeProps>(({ theme }) => `
  .Input__field {
    height: 40px;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid ${theme.base.color.gray300};
    border-radius: ${theme.base.borderRadii.v1};
    font-size: 16px;
    line-height: 24px;
    color: ${theme.base.color.dark}
  }

  .Input__field::placeholder {
    color: ${theme.base.color.gray500};
  }

  .Input__field:disabled {
    color: ${theme.base.color.gray500};
    background-color: ${theme.base.color.gray100};
  }

  .Input__errorMessage {
    margin-top: 4px;
    ${typography.error};
  }

  .Input__field:focus {
    border-color: ${theme.base.color.gray500};
    outline: none;
  }

  .Input__field_error,
  .Input__field_error:focus {
    border-color: ${theme.base.color.error};
  }

  .Input__field[type='number']::-webkit-inner-spin-button,
  .Input__field[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .Input__field[type='number'] {
    -moz-appearance: textfield;
  }
`);
