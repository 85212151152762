import { useParams, useSearchParams } from "next/navigation";
import { Category, CategoryProductsResponse } from "src/api/types";
import { fetchCategoryData } from "src/api/ubercatalogRequests";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import getActiveFilters from "src/utils/catalog/getActiveFilters";
import { useCatalogSorting } from "src/utils/catalog/useCatalogSorting";
import checkCategoryPageIsClosedFromIndexing from "src/utils/seo/checkCategoryPageIsClosedFromIndexing";
import { getFilterNamesFromActiveFilters } from "src/utils/seo/getFilterNamesFromActiveFilter";
import { getH1ForCategoryPage } from "src/utils/seo/getH1ForCategoryPage";
import { getQueryObjectFromSearchParams } from "src/utils/system/searchParamsHelpers";
import { BreadcrumbsProps } from "../../../components/Breadcrumbs/Breadcrumbs";
import { CategoryPageContentHeaderProps } from "../components/CategoryPageContentHeader/CategoryPageContentHeader";
import { ProductBoxProps } from "../components/ProductBox/ProductBox";
import { sendCategoryClick } from "../helpers/analytics";
import { getAggregateOffersMicroData } from "../helpers/getAggregateOffersMicroData";
import getCategoryLevel from "../helpers/getCategoryLevel";
import useBreadcrumbsItems from "./useBreadcrumbsCatalogItems";
import useLoadMoreAndPagination from "./useLoadMoreAndPagination";

interface Params {
  selectedCategory: Category;
  categoryData: CategoryProductsResponse;
}

const useCategoryPageProps = (params: Params) => {
  const { selectedCategory, categoryData } = params;

  const { filters, results: products, count_available: countAvailable, count, stats, pagination_limit } = categoryData;
  const { id, description, image_url, title } = selectedCategory;

  const lang = useLang();
  const searchParamsReadOnly = useSearchParams();
  const pageParams = useParams();
  const searchParams = getQueryObjectFromSearchParams(searchParamsReadOnly);

  const categories = useCategories();
  const breadcrumbs = useBreadcrumbsItems(id);
  const categoryLevel = getCategoryLevel(categories, id);
  const sortingProps = useCatalogSorting();
  const activeFilters = getActiveFilters({ pageParams, searchParams, filters });

  const hasActiveFilters = activeFilters.length > 0;
  const productsAreEmpty = count === 0;
  const emptyFilterStateIsVisible = hasActiveFilters && productsAreEmpty;
  const descriptionIsVisible = !searchParams.page && !hasActiveFilters;

  const handleBreadcrumbClick = (id: string) => {
    sendCategoryClick({
      place: 'category_bread_crumbs',
      page: `category_lvl${categoryLevel}`,
      category_id: id,
    });
  };

  const breadcrumbsProps: BreadcrumbsProps = {
    items: breadcrumbs,
    onBreadcrumbClick: handleBreadcrumbClick,
  };

  const productsCount = count > pagination_limit ? pagination_limit : count;

  const loadMoreAndPaginationProps = useLoadMoreAndPagination({
    products,
    productsCount,
    categoryId: id,
    sort: sortingProps.selectedSortItem.value,
    fetchDataHandler: fetchCategoryData,
  });

  const filterNames = getFilterNamesFromActiveFilters(activeFilters);
  const h1 = getH1ForCategoryPage(selectedCategory.title, filterNames);

  const isClosedFromIndexing = checkCategoryPageIsClosedFromIndexing({ pageParams, searchParams });

  const categoryPageContentHeaderProps: CategoryPageContentHeaderProps = {
    title: h1,
    categoryLevel,
    categories,
    selectedCategory,
  };

  const productBoxProps: ProductBoxProps = {
    countAvailable,
    activeFilters,
    sortingProps,
    loadMoreAndPaginationProps,
  };

  const iconSelectedCategory = image_url?.svg || image_url?.png;

  const categoryMicroData = getAggregateOffersMicroData({
    lang,
    stats,
    count,
    products,
    categoryImage: iconSelectedCategory,
    categoryName: title,
    isClosedFromIndexing,
  });

  const descriptionToShow = descriptionIsVisible ? description : '';

  return {
    categoryMicroData,
    categoryPageContentHeaderProps,
    loadMoreAndPaginationProps,
    breadcrumbsProps,
    productBoxProps,
    filters,
    description: descriptionToShow,
    emptyFilterStateIsVisible,
    productsAreEmpty,
  };
};

export default useCategoryPageProps;
