import { Fragment } from 'react';
import { Filter } from 'src/api/types';
import { HandleFilterFunc } from '../CheckList/useCheckList';
import CatalogFiltersMobileCategoriesContent from './CatalogFiltersMobileCategoriesContent';
import CatalogFiltersMobileCategoriesHeader from './CatalogFiltersMobileCategoriesHeader';
import { ActiveFilter } from 'src/utils/catalog/getActiveFilters';

interface CatalogFiltersMobileCategoriesProps {
  filters: Filter[];
  activeFilters: ActiveFilter[];
  openCategory(filter: Filter): void;
  handleFilter: HandleFilterFunc;
  deleteFilters: () => void;
  selectedQueryFilters: string[];
}

const CatalogFiltersMobileCategories = (props: CatalogFiltersMobileCategoriesProps) => {
  const {
    activeFilters,
    deleteFilters,
    ...restProps
  } = props;

  return (
    <Fragment>
      <CatalogFiltersMobileCategoriesHeader
        withButton={activeFilters.length > 0}
        onButtonClick={deleteFilters}
      />

      <CatalogFiltersMobileCategoriesContent
        activeFilters={activeFilters}
        {...restProps}
      />
    </Fragment>
  );
};

export default CatalogFiltersMobileCategories;

