'use client';
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import CatalogProductTile, { CatalogProductTileProps } from "../CatalogProductTile/CatalogProductTile";
import { useTheme } from "src/ui-kit/theme";

interface Props {
  productTiles: CatalogProductTileProps[];
}

const ProductsTable = (props: Props) => {
  const {
    productTiles,
  } = props;
  const theme = useTheme();

  return (
    <StylishBox
      className="ProductsTable"
      dataMarker="Products List"
      sbs={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {productTiles.map((productTiles) => (
        <StylishBox
          key={`${productTiles.product.ean}-table-item`}
          sbs={{
            flexShrink: '0',
            borderWidth: '0 1px 1px 0',
            borderStyle: 'solid',
            borderColor: theme.base.color.gray200,
            width: { general: '25%', phone: '50%' },
          }}
        >
          <CatalogProductTile
            {...productTiles}
          />
        </StylishBox>
      ))}
    </StylishBox>
  );
};

export default ProductsTable;
