import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';
import typography from '../../styles/typography';

export const PricesRangeStyled = styled.span<StyledThemeProps>(({ theme }) => `
  font-size: 20px;
  line-height: 24px;
  color: ${theme.base.color.dark};
  font-weight: 600;

  &.PricesRange_small {
    ${typography.text}
    font-weight: 600;
  }

  .PricesRange__currency {
    ${typography.text}
    font-weight: 600;
    padding-left: 4px;
  }
`);
