import Cookies from 'js-cookie';
import { usePathname, useSearchParams } from 'next/navigation';
import { getActiveSortValue, getSortList, SortOption } from 'src/utils/catalog/sortContent';
import makeSearchString from '../system/makeSearchString';
import { getQueryObjectFromSearchParams } from '../system/searchParamsHelpers';
import { useRouterWithLoading } from 'src/modules/global-loading';

export interface CatalogSortingProps {
  selectedSortItem: SortOption;
  sortList: SortOption[];
  onSort: (item: SortOption) => void;
}

export const useCatalogSorting = (): CatalogSortingProps => {
  const searchParams = useSearchParams();
  const { push } = useRouterWithLoading();
  const pathname = usePathname();
  const sortList = getSortList(pathname);
  const parsedSortCookie = Cookies.get('sort');
  const query = getQueryObjectFromSearchParams(searchParams);

  const activeSortKey = getActiveSortValue({
    sort: query.sort,
    list: sortList,
    cookieSort: parsedSortCookie,
  });
  const selectedSortItem = sortList.find(s => s.value === activeSortKey) || sortList[0];

  const onSort = ({ value }: SortOption) => {
    Cookies.set('sort', value);
    const {
      page,
      sort,
      ...restQuery
    } = query;

    const searchParams = makeSearchString({ sort: value, ...restQuery });
    push(`${pathname}${searchParams}`);
  };

  return {
    selectedSortItem,
    sortList,
    onSort,
  };
};
