import styled from '@emotion/styled';
import { desktop } from 'src/styles/media';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CheckboxStyled = styled.div<StyledThemeProps>(({ theme }) => `
  border: 1px solid ${theme.base.color.gray400};
  border-radius: ${theme.base.border.v1};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  transition: 0.2s border-color ease-in-out, 0.2s background-color ease-in-out;

  &.Checkbox_checked {
    background-color: ${theme.base.color.primary};
    border-color: ${theme.base.color.primary};
  }

  &.Checkbox_disabled {
    opacity: 0.4;
  }

  @media ${desktop} {
    &:not(.Checkbox_disabled) {
      cursor: pointer;
    }

    &.Checkbox_hovered:not(.Checkbox_disabled, .Checkbox_checked),
    &:hover:not(.Checkbox_disabled, .Checkbox_checked) {
      border-color: ${theme.base.color.gray500};
    }

    &.Checkbox_disabled {
      opacity: 0.4;
    }
  }
`);
