import useLocalize from 'src/services/localize/useLocalize';
import Button from 'src/ui-kit/Button';
import IconFont from 'src/ui-kit/IconFont';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { useTheme } from 'src/ui-kit/theme';
import { Typography } from 'src/ui-kit/Typography';
import getFilterOptionLabel from '../../utils/getFilterOptionLabel';
import { ActiveFilter } from 'src/utils/catalog/getActiveFilters';

export interface CatalogActiveFiltersListProps {
  activeFilters: ActiveFilter[];
  onClearAll: () => void;
  onDeleteItem: (key: string, value?: string) => void;
}

const CatalogActiveFiltersList = (props: CatalogActiveFiltersListProps) => {
  const { activeFilters, onClearAll, onDeleteItem } = props;

  const localize = useLocalize();
  const theme = useTheme();

  if (activeFilters.length <= 0) {
    return null;
  }

  return (
    <StylishBox
      dataMarker="CatalogActiveFiltersList"
      sbs={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: '8px',
      }}
    >
      <Button
        width="auto"
        variant='ghost'
        dataMarker='Clear all filters'
        onClick={onClearAll}
        sbs={{
          mr: '12px',
          mt: '2px',
          fontWeight: '400',
        }}
      >
        {localize('filters.clear-all')}
      </Button>
      {activeFilters.map(filter => {
        const handleDeleteItem = () => {
          const value = filter.type === 'price' ? filter.price : filter?.option?.value;

          onDeleteItem(filter.type, value);
        };

        return (
          <StylishBox
            key={filter.type === 'price' ? filter.type : filter?.option?.value}
            sbs={{
              mr: '12px',
              backgroundColor: theme.base.color.white,
              border: `${theme.base.border.v1} ${theme.base.color.gray300}`,
              borderRadius: theme.base.borderRadii.v1,
              p: '3px 8px 3px 12px',
              mb: '8px',
              cursor: 'default',
              hover: {
                borderColor: theme.base.color.gray500,
              },
            }}
          >
            <Typography
              element='span'
              variant="text6"
              sbs={{
                lineHeight: '24px',
              }}
            >
              {getFilterOptionLabel(filter, localize)}
            </Typography>
            <IconFont
              name="close"
              dataMarker='Close filter'
              elementProps={{
                onClick: handleDeleteItem,
              }}
              size="11px"
              color="gray300"
              sbs={{
                ml: '8px',
                cursor: 'pointer',
              }}

            />
          </StylishBox>
        );
      })}
    </StylishBox>
  );
};

export default CatalogActiveFiltersList;
