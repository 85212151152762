import css from 'styled-jsx/css';

const styles = css`
  .ZoomableImageSwitcher {
    cursor: zoom-in;
    height: 100%;
  }
  .ZoomableImageSwitcher__smallImg {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
  }
  .ZoomableImageSwitcher__largeImg {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .ZoomableImageSwitcher__smallImg_halfOpacity {
    opacity: 0.5;
  }
`;

export default styles;
