import { useParams, useSearchParams } from "next/navigation";
import { getCatalogSearchProducts } from "src/api/getCatalogSearchProducts";
import { SearchResponseDto } from "src/api/types";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import getActiveFilters from "src/utils/catalog/getActiveFilters";
import { useCatalogSorting } from "src/utils/catalog/useCatalogSorting";
import { getQueryObjectFromSearchParams } from "src/utils/system/searchParamsHelpers";
import { BreadcrumbsProps } from "../../../components/Breadcrumbs/Breadcrumbs";
import { ProductBoxProps } from "../components/ProductBox/ProductBox";
import useLoadMoreAndPagination from "./useLoadMoreAndPagination";

const useSearchPageProps = (searchData: SearchResponseDto) => {
  const { results: products, count: productsCount, filters, count_available: countAvailable, fuzzy } = searchData;

  const lang = useLang();
  const localize = useLocalize();
  const categories = useCategories();
  const sortingProps = useCatalogSorting();
  const searchParams = useSearchParams();
  const pageParams = useParams();

  const activeFilters = getActiveFilters({
    searchParams: getQueryObjectFromSearchParams(searchParams),
    pageParams,
    filters,
  });

  const loadMoreAndPaginationProps = useLoadMoreAndPagination({
    products,
    productsCount,
    sort: sortingProps.selectedSortItem.value,
    fetchDataHandler: getCatalogSearchProducts,
    isFuzzy: fuzzy,
  });

  const breadcrumbsProps: BreadcrumbsProps = {
    items: [
      { url: `/${lang}/`, title: localize('pages_main'), id: 'main' },
      { title: localize('search'), id: 'search' },
    ],
  };

  const productBoxProps: ProductBoxProps = {
    countAvailable,
    activeFilters,
    sortingProps,
    loadMoreAndPaginationProps,
  };

  const searchString = searchParams?.get('q') || '';
  const hasActiveFilters = activeFilters.filter(i => i.type !== 'q').length > 0;
  const isVisibleEmptySearchState = !hasActiveFilters && productsCount === 0;
  const isVisibleEmptyFilterState = hasActiveFilters && productsCount === 0;

  return {
    categories,
    breadcrumbsProps,
    productBoxProps,
    searchString,
    isVisibleEmptySearchState,
    isVisibleEmptyFilterState,
  };
};

export default useSearchPageProps;
