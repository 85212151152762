'use client';
import { Fragment } from "react";
import Responsive from "src/components/Responsive";
import ScriptLdJson from "src/components/ScriptLdJson/ScriptLdJson";
import { CatalogEmptySection, CatalogFilters, CatalogFiltersEmpty, CatalogFiltersMobile } from "src/modules/filters";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import { CategoryProductsResponse, Category as CategoryType } from "../../../../api/types";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import CategoryPageContentHeader from "../../components/CategoryPageContentHeader/CategoryPageContentHeader";
import CategoryTitle from "../../components/CategoryTitle/CategoryTitle";
import PageWrap from "../../components/PageLayout/PageWrap/PageWrap";
import PageWrapAside from "../../components/PageLayout/PageWrapAside/PageWrapAside";
import PageWrapBody from "../../components/PageLayout/PageWrapBody/PageWrapBody";
import ProductBox from "../../components/ProductBox/ProductBox";
import SeoText from "../../components/SeoText/SeoText";
import useCategoryPageProps from "../../hooks/useCategoryPageProps";

export interface CategoryPageProps {
  selectedCategory: CategoryType;
  categoryData: CategoryProductsResponse;
}

const CategoryPage = (props: CategoryPageProps) => {
  const { selectedCategory, categoryData } = props;
  const localize = useLocalize();
  const lang = useLang();

  const {
    categoryPageContentHeaderProps,
    breadcrumbsProps,
    productBoxProps,
    categoryMicroData,
    filters,
    description,
    emptyFilterStateIsVisible,
    productsAreEmpty,
  } = useCategoryPageProps({
    selectedCategory,
    categoryData,
  });

  const { activeFilters } = productBoxProps;

  if (emptyFilterStateIsVisible || !productsAreEmpty) {
    return (
      <Fragment>
        <Responsive displayOn="phoneTablet" displayNoneMode>
          <CatalogFiltersMobile
            filters={filters}
            activeFilters={activeFilters}
          />
        </Responsive>
        <Breadcrumbs {...breadcrumbsProps} />
        <CategoryPageContentHeader {...categoryPageContentHeaderProps} />

        <PageWrap>
          <PageWrapAside>
            <Responsive displayOn="desktop">
              <CatalogFilters filters={filters} />
            </Responsive>
          </PageWrapAside>
          <PageWrapBody>
            {emptyFilterStateIsVisible ? (
              <CatalogFiltersEmpty activeFilters={activeFilters} />
            ) : (
              <Fragment>
                <ProductBox {...productBoxProps} />

                {description &&
                  <SeoText content={description} />
                }
              </Fragment>
            )}
          </PageWrapBody>
          {categoryMicroData && (
            <ScriptLdJson body={categoryMicroData} />
          )}
        </PageWrap>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Breadcrumbs {...breadcrumbsProps} />
      <CategoryTitle title={categoryPageContentHeaderProps.title} />
      <PageWrap>
        <CatalogEmptySection
          buttonProps={{
            children: localize('to_the_main'),
            href:`/${lang}/`,
            dataMarker: 'Go To Main Page Button',
          }}
        />
      </PageWrap>
    </Fragment>
  );
};

export default CategoryPage;
