import { Fragment } from "react";
import Responsive from "src/components/Responsive";
import CategoriesSlider, { CategoriesSliderProps } from "../CategoriesSlider/CategoriesSlider";
import { itemsOnDesktop, itemsOnTablet } from "../CategoriesSlider/CategoriesSlider.styles";

type FirstLevelCategoriesSliderProps = Omit<CategoriesSliderProps, 'itemPerRow'>;

const FirstLevelCategoriesSlider = (props: FirstLevelCategoriesSliderProps) => (
  <Fragment>
    <Responsive displayOn='desktop'>
      <CategoriesSlider {...props} itemPerRow={itemsOnDesktop} />
    </Responsive>
    <Responsive displayOn='phoneTablet'>
      <CategoriesSlider {...props} itemPerRow={itemsOnTablet} />
    </Responsive>
  </Fragment>
);

export default FirstLevelCategoriesSlider;
