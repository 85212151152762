import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { TabProps } from "./TabsPanel";

const Tab = (props: TabProps) => {
  const { sbs, dataMarker, children } = props;

  return <StylishBox className="Tab" sbs={sbs} dataMarker={dataMarker}>{children}</StylishBox>;
};

export default Tab;
