import { FILTER_PRICE_SEPARATOR } from "src/data/constants";
import { RangeNumbers } from "./getPriceAndCurrencyText";

export default function getParsedFilterPriceValue(rawValue: string): RangeNumbers {
  const [rawMin, rawMax] = rawValue.split(FILTER_PRICE_SEPARATOR);

  const min = Number(rawMin) || 0;
  const max = Number(rawMax) || 0;

  return [min, max];
}
