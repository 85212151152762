import { Filter } from "src/api/types";
import { CURRENCY } from "src/data/constants";
import useLocalize from "src/services/localize/useLocalize";
import IconFont from "src/ui-kit/IconFont";
import { useTheme } from "src/ui-kit/theme";
import getFilterNamesWithCurrency from "../../utils/getFilterNamesWithCurrency";
import { CatalogFiltersMobileCategoriesButtonStyled } from './CatalogFiltersMobileCategoriesButton.styles';
import getPriceAndCurrencyText from "src/utils/catalog/getPriceAndCurrencyText";
import getParsedFilterPriceValue from "src/utils/catalog/getParsedFilterPriceValue";
import { ActiveFilter } from "src/utils/catalog/getActiveFilters";

interface CatalogFiltersMobileCategoriesButtonProps {
  filter: Filter;
  activeFilters: ActiveFilter[];
  onClick: (filter: Filter) => void;
}

const CatalogFiltersMobileCategoriesButton = (props: CatalogFiltersMobileCategoriesButtonProps) => {
  const { filter, activeFilters, onClick } = props;

  const localize = useLocalize();
  const theme = useTheme();

  const activeTypeFilters = activeFilters.filter(item => item.type === filter.key);
  const activeFiltersText = activeTypeFilters.map((f) => f.option && f.option.name).join(', ');
  const filterPriceValue = activeTypeFilters.map((f) => f.price).join('');
  const filterPriceName = getFilterNamesWithCurrency(filter.name, localize);
  const filterIsPrice = filter.key === 'price';

  return (
    <CatalogFiltersMobileCategoriesButtonStyled
      theme={theme}
      type='button'
      className='CatalogFiltersMobileCategoriesButton'
      onClick={() => onClick(filter)}
      data-marker={filter.key}
    >
      <span className='CatalogFiltersMobileCategoriesButton__title'>
        {filterIsPrice ? filterPriceName : filter.name}
      </span>

      {!!activeFiltersText.length && (
        <span className='CatalogFiltersMobileCategoriesButton__activeName'>
          {activeFiltersText}
        </span>
      )}

      {filterIsPrice && filterPriceValue && (
        <span className='CatalogFiltersMobileCategoriesButton__activeName'>
          {getPriceAndCurrencyText({
            price: getParsedFilterPriceValue(filterPriceValue),
            localize,
            isDesktop: false,
            currency: CURRENCY,
          })}
        </span>
      )}

      <span className='CatalogFiltersMobileCategoriesButton__icon'>
        <IconFont
          name='caret-bottom'
          color="gray400"
          size={{
            tablet: '11px',
            phone: '7px',
          }}
        />
      </span>
    </CatalogFiltersMobileCategoriesButtonStyled>
  );
};

export default CatalogFiltersMobileCategoriesButton;
