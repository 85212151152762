import styled from '@emotion/styled';
import { desktop } from 'src/styles/media';
import typography from 'src/styles/typography';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CheckboxWithLabelStyled = styled.div<StyledThemeProps>(({ theme }) => `
  ${typography.text};
  line-height: 24px;
  display: flex;

  &.CheckboxWithLabel_middle {
    align-items: center;
  }

  &.CheckboxWithLabel_top {
    align-items: flex-start;
  }

  .CheckboxWithLabel__label {
    margin-left: 10px;
    transition: 0.2s color ease-in-out;
    position: relative;
  }

  &.CheckboxWithLabel__fullWidth,
  .CheckboxWithLabel__label_fullWidth {
    width: 100%;
  }

  .CheckboxWithLabel__label_disabled {
    color: ${theme.base.color.gray100};
  }

  @media ${desktop} {
    .CheckboxWithLabel__label:not(.CheckboxWithLabel__label_disabled) {
      cursor: pointer;
    }
  }
`);
