import { MouseEventHandler } from "react";
import IconFont from "src/ui-kit/IconFont";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";

export interface FavoriteButtonProps {
  isActive: boolean;
  onClick(): void;
}

const FavoriteButton = (props: FavoriteButtonProps) => {
  const {
    onClick,
    isActive,
  } = props;

  const theme = useTheme();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <StylishBox
      element="button"
      dataMarker='Favorite button'
      className="FavoriteButton"
      elementProps={{
        onClick: handleClick,
        type: 'button',
      }}
      sbs={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        p: '0',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        color: isActive ? theme.base.color.error : theme.base.color.gray500,
        hover: {
          color: isActive ? theme.base.color.darkError : theme.base.color.gray600,
        },
      }}
    >
      <IconFont name={isActive ? 'heart-full' : 'heart'} />
    </StylishBox>
  );
};

export default FavoriteButton;
