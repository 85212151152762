import { RangeFilter } from "src/api/types";
import getParsedFilterPriceValue from "src/utils/catalog/getParsedFilterPriceValue";

export const getMinMaxQueryFilterValue = (filter: RangeFilter, queryPrice: string | undefined | null) => {
  const min = Math.floor(filter.min / 100) * 100;
  const max = Math.ceil(filter.max / 100) * 100;
  const [minValue, maxValue] = queryPrice
    ? getParsedFilterPriceValue(queryPrice)
    : [min, max];
  const resultMin = minValue < min ? minValue : min;
  const resultMax = maxValue > max ? maxValue : max;

  return {
    result: {
      min: resultMin,
      max: resultMax,
    },
    values: {
      min: minValue,
      max: maxValue,
    },
  };
};
